export const TOPIC_GENERIC_PAGE_HEADER_FRAGMENT = `
  fragment topicGenericPageHeaderFragment on Topic {
    uid
    name
    description
    imageFileResource {
      uid
      path
    }
    __typename
    _isFollowed(myUid: "%authUser%")
  }
`;
